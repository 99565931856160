<template>
  <div :class='pageClassByNavigationComponent'
    :style='minHeightStyle'>
    <component
      :is='pageNavigationComponent'
      :sidebar-links='sidebarConfigurations.sidebarLinks'
      :sidebar-page-title='sidebarConfigurations.sidebarPageTitle'
      :custom-background-stype='sidebarConfigurations.customBackgroundStyle' />
    <div class='flex-grow relative px-4 lg:px-0'>
      <welcome-conference-banner v-if='showConferenceBanner' class='mb-8 lg:mb-12' />
      <div class='py-8 lg:py-16'>
        <h1 class='text-3xl font-bold mb-4 lg:mb-8'>{{ pageTitle }}</h1>
        <div v-if='topHtmlContent' v-html='topHtmlContent'></div>
        <edit-form-field
          class='mb-6 w-full lg:w-2/3' 
          v-for='formField in signupFormFields'
          :key='formField.keyName'
          :edit-form-field='formField'
          :show-requirement-indicator-on-top='showRequirementIndicatorOnTop'
          :is-horizontal-layout='isFormFieldsHorizontalLayout'
          :value='fieldValue(formField.category, formField.keyName)'
          :requirement-indicator-helper-text='requirementIndicatorHelperText'
          @update-edit-form-field-value='updateSignupFormFieldValue(formField.category, formField.keyName, ...arguments)' />
        <div v-for='(term, index) in terms'
          :key='`term-${index}`'
          class='text-xs border rounded-md mb-6 w-full lg:w-2/3'>
          <div class='bg-gray-50 p-4 overflow-y-auto max-h-56' v-html='term.text'></div>
          <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-sm'>
            <input type='checkbox' :name='`checkbox-${index}`' v-model='term.agreed'>
            <span class='px-1'>{{ term.confirmationText }}</span>
          </label>
        </div>
        <button 
          class='px-4 py-3 hover:shadow-md uppercase border rounded-md submit-button w-full lg:w-1/2 mb-6'
          @click='submitSignup'
          :disabled='disabledSignupButton'
          :class='disabledSignupButtonClass'>
          {{ signupButtonText }}
        </button>
        <ul v-if='!showRequirementIndicatorOnTop'
          class='list-disc list-inside text-red-600 text-sm'>
          <div v-for='formField in signupFormFields'
            :key='formField.keyName'>
            <li v-if='isFieldValutEmpty(formField)'>
              {{ formField.name }} {{requirementIndicatorHelperText}}
            </li>
          </div>
        </ul>
        <ul class='list-disc list-inside text-red-600 text-sm'>
          <div v-for='requirement in additionalRequirementsIndicators'
            :key='requirement.keyName'>
            <li>
              {{ requirement.metaData.indicatorText }}
            </li>
          </div>
        </ul>
      </div>
      <welcome-sponsor-carousel  v-if='showSponsorCarousel'  class='max-w-4xl mt-16 lg:mt-20'/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import camelCase from 'lodash/camelCase'
import { mapFields } from 'vuex-map-fields'
import jsonLogic  from 'json-logic-js'
import dayjs      from 'dayjs'

export default {
  name: 'IcolaSignup',
  components: {
    'tabs-page-navigation': () => import('@/components/common/TabsPageNavigation'),
    'sidebar-page-navigation': () => import('@/components/common/SidebarPageNavigation'),
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      validPhoneNumbers: [],
    }
  },
  watch: {
    'termsDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.terms = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'profileDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.profile = cloneDeep(newVal)
          this.getProfile().then((resp) => {
            Object.keys(this.profile).forEach(key => {
              if (key === 'extraInfo') {
                let dieteLicenseNumber = this.profile[key].dieteLicenseNumber ? this.profile[key].dieteLicenseNumber : ''
                let degree = this.profile[key].degree ? this.profile[key].degree : []
                let hospitalType = this.profile[key].hospitalType ? this.profile[key].hospitalType : ''
                let medicalProfession = this.profile[key].medicalProfession ? this.profile[key].medicalProfession : ''
                let specialistLicenseNumber = this.profile[key].specialistLicenseNumber ? this.profile[key].specialistLicenseNumber : ''
                this.profile[key] = {
                  'degree': degree,
                  'hospitalType': hospitalType,
                  'medicalProfession': medicalProfession,
                  'dieteLicenseNumber': dieteLicenseNumber,
                  'specialistLicenseNumber': specialistLicenseNumber
                }
              } else if (key === 'phoneNumber') {
                let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === 'phone_number')
                if (index > -1) {
                  this.validPhoneNumbers[index].phoneNumber = resp[key]
                  this.validPhoneNumbers[index].isValidPhoneNumber = true
                } else {
                  this.validPhoneNumbers.push({
                    keyName: 'phone_number',
                    phoneNumber: resp[key],
                    isValidPhoneNumber: true
                  })
                }
                this.profile[key] = resp[key]
              } else {
                this.profile[key] = resp[key]
              }
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
    'registrationDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.registrationData = cloneDeep(newVal)
          this.getRegistrationData().then((resp) => {
            Object.keys(this.registrationData).forEach(key => {
              this.registrationData[key] = resp[key]
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
    'membershipDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.membershipData = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'profile',
      'terms',
      'registrationData',
      'membershipData',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'showingEventSignupConfigurations',
    ]),
    pageNavigationComponent () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.pageNavigationComponent) ? this.showingEventSignupConfigurations.pageNavigationComponent : ''
    },
    pageClassByNavigationComponent () {
      return this.pageNavigationComponent === 'sidebar-page-navigation' ? 'flex flex-col lg:flex-row justify-between items-stretch gap-x-16' : ''
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    pageTitle () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.pageTitle) ? this.showingEventSignupConfigurations.pageTitle : 'Sign up'
    },
    signupButtonText () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.signupButtonText) ? this.showingEventSignupConfigurations.signupButtonText : 'Submit Signup'
    },
    topHtmlContent () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.topHtmlContent) ? this.showingEventSignupConfigurations.topHtmlContent : ''
    },

    termsDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.termsDefault) ? this.showingEventSignupConfigurations.termsDefault : []
    },
    profileDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.profileDefault) ? this.showingEventSignupConfigurations.profileDefault : {}
    },
    registrationDataDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.registrationDataDefault) ? this.showingEventSignupConfigurations.registrationDataDefault : {}
    },
    membershipDataDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.membershipDataDefault) ? this.showingEventSignupConfigurations.membershipDataDefault : {}
    },
    currentDateTimeString () {
      return dayjs().format('YYYYMMDDHHmm')
    },
    signupFormFields () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.signupFormFields) ?
        this.showingEventSignupConfigurations.signupFormFields.concat()
        .filter(field => field.keyName !== 'email' && field.keyName !== 'password' && field.keyName !== 'password_confirmation')
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    sidebarConfigurations () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.sidebarConfigurations) ? this.showingEventSignupConfigurations.sidebarConfigurations : {}
    },
    isFormFieldsHorizontalLayout () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.isFormFieldsHorizontalLayout) ? this.showingEventSignupConfigurations.isFormFieldsHorizontalLayout : false
    },
    showRequirementIndicatorOnTop () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.showRequirementIndicatorOnTop) ? this.showingEventSignupConfigurations.showRequirementIndicatorOnTop : false
    },
    requirementIndicatorHelperText () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.requirementIndicatorHelperText) ? this.showingEventSignupConfigurations.requirementIndicatorHelperText : 'is required.'
    },
    additionalRequirementIndicatorsHtml () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.additionalRequirementIndicatorsHtml) ? this.showingEventSignupConfigurations.additionalRequirementIndicatorsHtml : ''
    },
    signupDoneMessages () {
      return (this.showingEventSignupConfigurations.signupDoneAlertMessage) ? this.showingEventSignupConfigurations.signupDoneAlertMessage : 'signup is complete'
    },
    signupErrorAlertMessages () {
      return (this.showingEventSignupConfigurations.signupErrorAlertMessages) ? this.showingEventSignupConfigurations.signupErrorAlertMessages : []
    },
    signupGeneralErrorAlertMessage () {
      return this.signupErrorAlertMessages.length > 0 &&
             this.signupErrorAlertMessages.find(message => message.error === 'general') ? 
             this.signupErrorAlertMessages.find(message => message.error === 'general').message : 'Cannot submit your signup. Please contact us.'
    },
    needToCheckPhoneNumber () {
      return !this.validPhoneNumbers.every(phoneNumber => phoneNumber.isValidPhoneNumber)
    },
    needToAgreedTerms () {
      return !this.terms.filter(term => term.required).every(term => term.agreed)
    },
    additionalRequirements () {
      return {
        needToCheckPhoneNumber: this.needToCheckPhoneNumber,
        needToAgreedTerms: this.needToAgreedTerms,
      }
    },
    additionalRequirementsIndicators () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.additionalRequirementsIndicators) ?
        this.showingEventSignupConfigurations.additionalRequirementsIndicators.concat()
        .filter((requirement) => {
          let condition = requirement.metaData.showCondition ? requirement.metaData.showCondition : false
          return jsonLogic.apply(condition, {additionalRequirements: this.additionalRequirements})
        }) : []
    },
    disabledSignupButton () {
      return this.signupFormFields.map(field => this.fieldValue(field.category, field.keyName)).some(value => !value ||
        typeof value === 'string' && !value ||
        typeof value === 'object' && !Object.keys(value).length ||
        Array.isArray(value) && !value.length) ||
      Object.values(this.additionalRequirements).some(requirement => requirement)
    },
    disabledSignupButtonClass () {
      return this.disabledSignupButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    redirectRouteObject () {
      const redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Home'
      const redirectRouteSponsorId = this.$route.query.sponsor_id ? this.$route.query.sponsor_id : ''

      let routeObject = { name: redirectRoute }

      if (redirectRoute === 'Sponsor' && redirectRouteSponsorId) {
        routeObject['query'] = {sponsor_id: redirectRouteSponsorId }
      }
      return routeObject
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
      'patchProfile',
      'patchRegistrationData',
      'patchTerms',
      'patchMembershipData',
      'checkTokenStatus',
      'getRegistrationData',
    ]),
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'profile') {
        if (arr.length === 2) {
          if (keyName.includes('phone_number')) { 
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            val = index > -1 && this.profile[arr[0]][arr[1]] ? {
              phoneNumber: this.validPhoneNumbers[index].phoneNumber,
              isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else {
            val = this.profile[arr[0]][arr[1]]
          }
        } else if (arr.length === 1) {
          if (keyName === 'address') {
            val = this.profile.address && this.profile.postalCode ? {
              address: this.profile.address,
              postalCode: this.profile.postalCode,
            } : null
          } else if (keyName === 'phone_number') {
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === 'phone_number')
            val = index > -1 && this.profile[arr[0]] ? {
                phoneNumber: this.validPhoneNumbers[index].phoneNumber,
                isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else if (keyName === 'organization_hospital_name') {
            val = this.profile.organization && this.profile.hospitalName ? {
              organization: this.profile.organization,
              hospitalName: this.profile.hospitalName,
            } : null
          } else {
            val = this.profile[arr[0]]
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          val = this.signupParams[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.signupParams[arr[0]]
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          val = this.registrationData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.registrationData[arr[0]]
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          val = this.membershipData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.membershipData[arr[0]]
        }
      }
      return val
    },
    updateSignupFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'profile') {
        if (arr.length === 2) { //extra info
          if (keyName.includes('phone_number')) { 
            this.profile[arr[0]][arr[1]] = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else {
            this.profile[arr[0]][arr[1]] = value
          }
        } else if (arr.length === 1) {
          if (keyName === 'phone_number') {
            this.profile.phoneNumber = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else if (keyName === 'address') {
            this.profile.address = value.address
            this.profile.postalCode = value.postalCode
          } else if (keyName === 'organization_hospital_name') {
            this.profile.organization = value.organization
            this.profile.hospitalName = value.hospitalName
          } else {
            this.profile[arr[0]] = value
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          this.signupParams[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.signupParams[arr[0]] = value
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          this.registrationData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.registrationData[arr[0]] = value
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          this.membershipData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.membershipData[arr[0]] = value
        }
      }
    },
    signupErrorMessage (error) {
      let errorMessagePair = this.signupErrorAlertMessages.find(message => message.error === error)
      let alertMessage = errorMessagePair ? errorMessagePair.message : this.loginGeneralErrorAlertMessage
      this.$alert(alertMessage, {
        type: 'warning',
        confirmButtonText: 'Close'
      })
    },
    submitSignup () {
      let patchTerms = this.patchTerms()
      let patchMembershipData = this.patchMembershipData(this.membershipData)
      let patchRegistrationData = this.patchRegistrationData(this.registrationData)
      let patchProfile = this.patchProfile(this.profile)
  
      Promise.all([patchTerms, patchMembershipData, patchProfile, patchRegistrationData]).then(() => {
        this.$alert(this.signupDoneMessages, {
          confirmButtonText: 'OK',
          type: 'success',
          showClose: false,
        }).then(() => {
          this.$router.push(this.redirectRouteObject)
        }).catch(() => {})
      })
    },
    isFieldValutEmpty (field) {
      let value = this.fieldValue(field.category, field.keyName)
      return !value ||
        typeof value === 'string' && !value ||
        typeof value === 'object' && !Object.keys(value).length ||
        Array.isArray(value) && !value.length
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login'}).catch(() => {})
      }
    })
  },
}
</script>

<style type='scss' scoped>
.submit-button {
  @apply text-white;
  background-color: var(--eventMainColor);
}

.background-style {
  background: transparent; 
  width: 100%;
}
.sidebar-navigation-links .router-link-active {
  @apply font-bold;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>
